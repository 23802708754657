import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import Signup from './components/Signup';
import ForgotPassword from './components/ForgotPassword';
import ActivityLog from './components/ActivityLog';
import LandingPage from './components/LandingPage';
import ContactUs from './components/ContactUs';
import FilterView from './components/FilterView';
import FilterByTags from './components/FilterByTags';
import AllTasksFilter from './components/AllTasksFilter';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/activity-log" component={ActivityLog} />
          <PrivateRoute path="/contact" component={ContactUs} />
          <PrivateRoute path="/filter" exact component={FilterView} />
          <PrivateRoute path="/filter/tags" component={FilterByTags} />
          <PrivateRoute path="/filter/all-tasks" component={AllTasksFilter} />
          <Route path="/signup" component={Signup} />
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Redirect to="/" />
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;