import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc, collection, addDoc, query, where, getDocs, deleteDoc, updateDoc } from 'firebase/firestore';
import Sidebar from './Sidebar';
import TaskList from './TaskList';
import AddTodoForm from './AddTodoForm';
import ProfileDropdown from './ProfileDropdown';
import SettingsModal from './SettingsModal';
import { logActivity } from '../utils/logActivity';
import { filterTodos } from '../utils/todoUtils';
import { FiMenu } from 'react-icons/fi';
import FilterByTags from './FilterByTags';
import AllTasksFilter from './AllTasksFilter';

function Dashboard() {
  const { currentUser, logout } = useAuth();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [userName, setUserName] = useState('');
  const [userPhotoURL, setUserPhotoURL] = useState('');
  const [todos, setTodos] = useState([]);
  const [activeView, setActiveView] = useState('inbox');
  const [projects, setProjects] = useState([]);
  const [existingTags, setExistingTags] = useState([]);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [isDesktopSidebarOpen, setIsDesktopSidebarOpen] = useState(true);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);

  const counts = useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return {
      inbox: todos.filter(todo => !todo.completed && (!todo.dueDate || new Date(todo.dueDate) >= today)).length,
      today: todos.filter(todo => !todo.completed && todo.dueDate && new Date(todo.dueDate).getTime() === today.getTime()).length,
      completed: todos.filter(todo => todo.completed).length,
      missed: todos.filter(todo => {
        if (todo.completed) return false;
        if (todo.dueDate) return new Date(todo.dueDate) < today;
        const createdAt = new Date(todo.createdAt);
        return (today - createdAt) / (1000 * 60 * 60) > 24;
      }).length
    };
  }, [todos]);

  const projectsWithCounts = useMemo(() => {
    return projects.map(project => ({
      ...project,
      taskCount: todos.filter(todo => 
        !todo.completed && todo.projects && todo.projects.includes(project.name)
      ).length
    }));
  }, [todos, projects]);

  const fetchTodos = useCallback(async () => {
    const q = query(collection(db, 'todos'), where('userId', '==', currentUser.uid));
    const querySnapshot = await getDocs(q);
    const todoList = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      dueDate: doc.data().dueDate ? new Date(doc.data().dueDate.seconds * 1000) : null,
      createdAt: new Date(doc.data().createdAt.seconds * 1000)
    }));
    setTodos(todoList);
  }, [currentUser.uid]);

  const fetchProjects = useCallback(async () => {
    const q = query(collection(db, 'projects'), where('userId', '==', currentUser.uid));
    const querySnapshot = await getDocs(q);
    const projectList = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setProjects(projectList);
  }, [currentUser.uid]);

  const fetchTags = useCallback(async () => {
    const q = query(collection(db, 'tags'), where('userId', '==', currentUser.uid));
    const querySnapshot = await getDocs(q);
    const tagList = querySnapshot.docs.map(doc => doc.data().name);
    setExistingTags(tagList);
  }, [currentUser.uid]);

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      try {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          setUserName(userDoc.data().name || '');
          setUserPhotoURL(userDoc.data().photoURL || '');
        }
        await fetchTodos();
        await fetchProjects();
        await fetchTags();
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [currentUser.uid, fetchTodos, fetchProjects, fetchTags]);

  const handleAddTodo = useCallback(async (content, dueDate, tags, projectNames, emoji) => {
    try {
      const newTodo = {
        userId: currentUser.uid,
        content,
        dueDate: dueDate ? new Date(dueDate) : null,
        tags,
        projects: projectNames,
        completed: false,
        createdAt: new Date(),
        emoji: emoji  // Store the emoji with the todo
      };

      const docRef = await addDoc(collection(db, 'todos'), newTodo);
      const addedTodo = { id: docRef.id, ...newTodo };
      setTodos(prevTodos => [...prevTodos, addedTodo]);

      await logActivity(currentUser.uid, 'Added Todo', { todoId: docRef.id, content });
    } catch (error) {
      console.error('Error adding todo:', error);
    }
  }, [currentUser.uid]);

  const handleToggleTodo = useCallback(async (todoId) => {
    const todoToUpdate = todos.find(todo => todo.id === todoId);
    if (!todoToUpdate) return;

    const updatedTodo = { ...todoToUpdate, completed: !todoToUpdate.completed };
    try {
      await updateDoc(doc(db, 'todos', todoId), { completed: updatedTodo.completed });
      setTodos(prevTodos => prevTodos.map(todo => todo.id === todoId ? updatedTodo : todo));
      await logActivity(currentUser.uid, `Marked Todo as ${updatedTodo.completed ? 'Complete' : 'Incomplete'}`, { todoId, content: todoToUpdate.content });
    } catch (error) {
      console.error('Error toggling todo:', error);
    }
  }, [currentUser.uid, todos]);

  const handleDeleteTodo = useCallback(async (todoId) => {
    try {
      await deleteDoc(doc(db, 'todos', todoId));
      setTodos(prevTodos => prevTodos.filter(todo => todo.id !== todoId));
      await logActivity(currentUser.uid, 'Deleted Todo', { todoId });
    } catch (error) {
      console.error('Error deleting todo:', error);
    }
  }, [currentUser.uid]);

  const handleEditTodo = useCallback(async (updatedTodo) => {
    try {
      const todoToUpdate = {
        ...updatedTodo,
        dueDate: updatedTodo.dueDate ? new Date(updatedTodo.dueDate) : null,
      };

      await updateDoc(doc(db, 'todos', updatedTodo.id), todoToUpdate);
      setTodos(prevTodos => prevTodos.map(todo => todo.id === updatedTodo.id ? todoToUpdate : todo));
      await logActivity(currentUser.uid, 'Edited Todo', { todoId: updatedTodo.id, content: updatedTodo.content });
    } catch (error) {
      console.error('Error editing todo:', error);
    }
  }, [currentUser.uid]);

  const handleAddProject = useCallback(async (projectName) => {
    try {
      const newProject = {
        userId: currentUser.uid,
        name: projectName,
        taskCount: 0
      };
      const docRef = await addDoc(collection(db, 'projects'), newProject);
      setProjects(prevProjects => [...prevProjects, { id: docRef.id, ...newProject }]);
      await logActivity(currentUser.uid, 'Added Project', { projectId: docRef.id, projectName });
    } catch (error) {
      console.error('Error adding project:', error);
    }
  }, [currentUser.uid]);

  const handleEditProject = useCallback(async (projectId, newName) => {
    try {
      await updateDoc(doc(db, 'projects', projectId), { name: newName });
      setProjects(prevProjects => 
        prevProjects.map(project => 
          project.id === projectId ? { ...project, name: newName } : project
        )
      );
      await logActivity(currentUser.uid, 'Edited Project', { projectId, newName });
    } catch (error) {
      console.error('Error editing project:', error);
    }
  }, [currentUser.uid]);

  const handleDeleteProject = useCallback(async (projectId) => {
    try {
      await deleteDoc(doc(db, 'projects', projectId));
      setProjects(prevProjects => prevProjects.filter(project => project.id !== projectId));
      await logActivity(currentUser.uid, 'Deleted Project', { projectId });
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  }, [currentUser.uid]);

  const handleAddTag = useCallback(async (tagName) => {
    try {
      const newTag = {
        userId: currentUser.uid,
        name: tagName
      };
      await addDoc(collection(db, 'tags'), newTag);
      setExistingTags(prevTags => [...prevTags, tagName]);
      await logActivity(currentUser.uid, 'Added Tag', { tagName });
    } catch (error) {
      console.error('Error adding tag:', error);
    }
  }, [currentUser.uid]);

  const handleProfileUpdate = useCallback(async (updatedProfile) => {
    try {
      await updateDoc(doc(db, 'users', currentUser.uid), updatedProfile);
      setUserName(updatedProfile.name || '');
      setUserPhotoURL(updatedProfile.photoURL || '');
      await logActivity(currentUser.uid, 'Updated Profile');
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  }, [currentUser.uid]);

  const filteredTodos = useMemo(() => filterTodos(todos, activeView, projects), [todos, activeView, projects]);

  const handleLogoutClick = () => {
    setShowLogoutConfirmation(true);
  };

  const handleConfirmLogout = () => {
    setShowLogoutConfirmation(false);
    logout();
  };

  const handleCancelLogout = () => {
    setShowLogoutConfirmation(false);
  };

  const openSettingsModal = useCallback(() => {
    setIsSettingsModalOpen(true);
  }, []);

  const renderContent = () => {
    switch (activeView) {
      case 'inbox':
      case 'today':
      case 'upcoming':
      case 'completed':
      case 'missed':
        return (
          <>
            <AddTodoForm 
              onAddTodo={handleAddTodo} 
              existingTags={existingTags} 
              projects={projects}
              onAddTag={handleAddTag}
            />
            <TaskList 
              todos={filteredTodos} 
              onToggle={handleToggleTodo} 
              onDelete={handleDeleteTodo} 
              onEdit={handleEditTodo} 
              projects={projects} 
              tags={existingTags} 
              onAddTag={handleAddTag} 
            />
          </>
        );
      case 'filter-tags':
        return <FilterByTags todos={todos} tags={existingTags} onToggle={handleToggleTodo} onDelete={handleDeleteTodo} onEdit={handleEditTodo} />;
      case 'filter-all-tasks':
        return <AllTasksFilter todos={todos} onToggle={handleToggleTodo} onDelete={handleDeleteTodo} onEdit={handleEditTodo} />;
      default:
        return (
          <>
            <AddTodoForm 
              onAddTodo={handleAddTodo} 
              existingTags={existingTags} 
              projects={projects}
              onAddTag={handleAddTag}
            />
            <TaskList 
              todos={filteredTodos} 
              onToggle={handleToggleTodo} 
              onDelete={handleDeleteTodo} 
              onEdit={handleEditTodo} 
              projects={projects} 
              tags={existingTags} 
              onAddTag={handleAddTag} 
            />
          </>
        );
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex h-screen bg-gradient-to-br from-gray-900 via-purple-900 to-violet-600">
      <Sidebar 
        activeView={activeView} 
        setActiveView={setActiveView} 
        counts={counts}
        projects={projectsWithCounts}
        onLogout={logout}
        onAddProject={handleAddProject}
        onEditProject={handleEditProject}
        onDeleteProject={handleDeleteProject}
        isOpen={isMobileSidebarOpen}
        setIsOpen={setIsMobileSidebarOpen}
        isDesktopOpen={isDesktopSidebarOpen}
        setIsDesktopOpen={setIsDesktopSidebarOpen}
        onLogoutClick={handleLogoutClick}
      />
      <div className="flex flex-col flex-1 overflow-hidden">
        <header className="bg-black bg-opacity-30 backdrop-filter backdrop-blur-lg z-10 flex justify-between items-center p-4">
          <div className="flex items-center">
            <button
              onClick={() => setIsMobileSidebarOpen(!isMobileSidebarOpen)}
              className="text-white mr-4 md:hidden"
            >
              <FiMenu size={24} />
            </button>
            <h1 className="text-xl md:text-2xl font-semibold text-purple-300">Todo App</h1>
          </div>
          <ProfileDropdown 
            userName={userName || currentUser.email} 
            photoURL={userPhotoURL}
            openSettingsModal={openSettingsModal}
          />
        </header>
        <main className={`flex-1 overflow-y-auto p-4 transition-all duration-300 ${isDesktopSidebarOpen ? 'md:ml-0' : 'md:ml-16'}`}>
          <div className="max-w-4xl mx-auto px-4 py-4 md:px-8">
            {renderContent()}
          </div>
        </main>
      </div>
      <SettingsModal 
        isOpen={isSettingsModalOpen} 
        onClose={() => setIsSettingsModalOpen(false)} 
        onProfileUpdate={handleProfileUpdate}
      />
      
      {/* Logout Confirmation Modal */}
      {showLogoutConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 rounded-lg p-6 max-w-sm">
            <h3 className="text-lg font-semibold mb-4 text-white">Confirm Logout</h3>
            <p className="mb-4 text-gray-300">Are you sure you want to log out?</p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={handleCancelLogout}
                className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmLogout}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
