import React, { useState } from 'react';
import { FiTag, FiList } from 'react-icons/fi';
import { Link } from 'react-router-dom';

function FilterView() {
  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Filter Tasks</h2>
      <div className="space-y-4">
        <Link to="/filter/tags" className="flex items-center p-2 bg-gray-700 rounded-lg hover:bg-gray-600">
          <FiTag className="mr-2" />
          <span>Filter by Tags</span>
        </Link>
        <Link to="/filter/all-tasks" className="flex items-center p-2 bg-gray-700 rounded-lg hover:bg-gray-600">
          <FiList className="mr-2" />
          <span>All Tasks</span>
        </Link>
      </div>
    </div>
  );
}

export default FilterView;