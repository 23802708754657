import React from 'react';
import { FiX } from 'react-icons/fi';
import SidebarContent from './SidebarContent';

function MobileSidebar({ isOpen, setIsOpen, ...props }) {
  return (
    <div className={`md:hidden fixed inset-y-0 left-0 z-30 w-64 bg-black bg-opacity-30 backdrop-filter backdrop-blur-lg transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out flex flex-col`}>
      <div className="flex items-center justify-between p-4">
        <h2 className="text-xl font-bold text-white">Menu</h2>
        <button onClick={() => setIsOpen(false)} className="text-white">
          <FiX size={24} />
        </button>
      </div>
      <div className="flex-1 overflow-y-auto">
        <SidebarContent isOpen={true} {...props} />
      </div>
    </div>
  );
}

export default MobileSidebar;