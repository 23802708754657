import React, { useState } from 'react';
import { FiUpload } from 'react-icons/fi';
import { storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../contexts/AuthContext';
import { logActivity } from '../utils/logActivity';

function ProfilePhotoUpload({ currentPhotoURL, onPhotoUpdate }) {
  const [uploading, setUploading] = useState(false);
  const { currentUser } = useAuth();

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploading(true);
      const storageRef = ref(storage, `profile_photos/${currentUser.uid}`);
      try {
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        onPhotoUpdate(downloadURL);
        await logActivity(currentUser.uid, 'Updated Profile Photo');
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        setUploading(false);
      }
    }
  };

  return (
    <div className="flex items-center space-x-4">
      <img
        src={currentPhotoURL || 'https://via.placeholder.com/100'}
        alt="Profile"
        className="w-20 h-20 rounded-full object-cover"
      />
      <label className="cursor-pointer bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded inline-flex items-center">
        <FiUpload className="mr-2" />
        <span>{uploading ? 'Uploading...' : 'Upload Photo'}</span>
        <input
          type="file"
          className="hidden"
          accept="image/*"
          onChange={handleFileChange}
          disabled={uploading}
        />
      </label>
    </div>
  );
}

export default ProfilePhotoUpload;