import React from 'react';
import MobileSidebar from './MobileSidebar';
import DesktopSidebar from './DesktopSidebar';

function Sidebar({ onLogoutClick, ...props }) {
  return (
    <>
      <MobileSidebar onLogoutClick={onLogoutClick} {...props} />
      <DesktopSidebar onLogoutClick={onLogoutClick} {...props} />
    </>
  );
}

export default Sidebar;