import React, { useState, useRef, useEffect } from 'react';
import { FiUser, FiSettings, FiMail, FiActivity } from 'react-icons/fi';
import { Link } from 'react-router-dom';

function ProfileDropdown({ userName, photoURL, openSettingsModal }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 focus:outline-none"
      >
        {photoURL ? (
          <img src={photoURL} alt="Profile" className="w-8 h-8 rounded-full" />
        ) : (
          <FiUser className="w-8 h-8 text-white" />
        )}
        <span className="text-white">{userName}</span>
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
          <button
            onClick={() => {
              openSettingsModal();
              setIsOpen(false);
            }}
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
          >
            <FiSettings className="inline-block mr-2" /> Settings
          </button>
          <Link
            to="/activity-log"
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
            onClick={() => setIsOpen(false)}
          >
            <FiActivity className="inline-block mr-2" /> Activity Log
          </Link>
          <Link
            to="/contact"
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
            onClick={() => setIsOpen(false)}
          >
            <FiMail className="inline-block mr-2" /> Contact Us
          </Link>
        </div>
      )}
    </div>
  );
}

export default ProfileDropdown;