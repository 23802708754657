import React, { useState } from 'react';
import { FiInbox, FiCalendar, FiStar, FiCheckCircle, FiAlertCircle, FiPlus, FiFolder, FiMoreVertical, FiEdit2, FiTrash2, FiSave, FiLogOut, FiFilter, FiChevronDown, FiChevronUp, FiTag, FiList } from 'react-icons/fi';

function SidebarItem({ icon: Icon, text, active, onClick, count, isOpen }) {
  return (
    <li 
      className={`flex items-center p-2 rounded-lg cursor-pointer ${active ? 'bg-purple-700 text-white' : 'text-gray-300 hover:bg-purple-600 hover:text-white'}`}
      onClick={onClick}
    >
      <Icon className={`${isOpen ? 'mr-2' : 'mx-auto'}`} />
      {isOpen && (
        <>
          <span>{text}</span>
          {count !== undefined && <span className="ml-auto bg-purple-500 text-white rounded-full px-2 py-1 text-xs">{count}</span>}
        </>
      )}
    </li>
  );
}

function SidebarContent({ activeView, setActiveView, counts, projects, onLogout, onAddProject, onEditProject, onDeleteProject, isOpen, onLogoutClick }) {
  const [isAddingProject, setIsAddingProject] = useState(false);
  const [newProjectName, setNewProjectName] = useState('');
  const [editingProjectId, setEditingProjectId] = useState(null);
  const [editingProjectName, setEditingProjectName] = useState('');
  const [showOptionsForProject, setShowOptionsForProject] = useState(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleAddProject = () => {
    if (newProjectName.trim()) {
      onAddProject(newProjectName.trim());
      setNewProjectName('');
      setIsAddingProject(false);
    }
  };

  const handleEditProject = (projectId, projectName) => {
    setEditingProjectId(projectId);
    setEditingProjectName(projectName);
    setShowOptionsForProject(null);
  };

  const handleSaveProjectEdit = (projectId) => {
    if (editingProjectName.trim()) {
      onEditProject(projectId, editingProjectName.trim());
      setEditingProjectId(null);
      setEditingProjectName('');
    }
  };

  const handleCancelEdit = () => {
    setEditingProjectId(null);
    setEditingProjectName('');
  };

  return (
    <div className="flex flex-col h-full">
      <nav className="flex-1 overflow-y-auto">
        <ul className="space-y-2 mb-4">
          <SidebarItem icon={FiInbox} text="Inbox" active={activeView === 'inbox'} onClick={() => setActiveView('inbox')} count={counts.inbox} isOpen={isOpen} />
          <SidebarItem icon={FiCalendar} text="Today" active={activeView === 'today'} onClick={() => setActiveView('today')} count={counts.today} isOpen={isOpen} />
          <SidebarItem icon={FiStar} text="Upcoming" active={activeView === 'upcoming'} onClick={() => setActiveView('upcoming')} isOpen={isOpen} />
          <SidebarItem icon={FiCheckCircle} text="Completed" active={activeView === 'completed'} onClick={() => setActiveView('completed')} count={counts.completed} isOpen={isOpen} />
          <SidebarItem icon={FiAlertCircle} text="Missed" active={activeView === 'missed'} onClick={() => setActiveView('missed')} count={counts.missed} isOpen={isOpen} />
          <li>
            <div
              className={`flex items-center p-2 rounded-lg cursor-pointer ${activeView.startsWith('filter') ? 'bg-purple-700 text-white' : 'text-gray-300 hover:bg-purple-600 hover:text-white'}`}
              onClick={() => setIsFilterOpen(!isFilterOpen)}
            >
              <FiFilter className={`${isOpen ? 'mr-2' : 'mx-auto'}`} />
              {isOpen && (
                <>
                  <span>Filter</span>
                  {isFilterOpen ? <FiChevronUp className="ml-auto" /> : <FiChevronDown className="ml-auto" />}
                </>
              )}
            </div>
            {isFilterOpen && isOpen && (
              <ul className="ml-4 mt-2 space-y-2">
                <li
                  className={`flex items-center cursor-pointer ${activeView === 'filter-tags' ? 'text-purple-300' : 'text-gray-400 hover:text-white'}`}
                  onClick={() => setActiveView('filter-tags')}
                >
                  <FiTag className="mr-2" />
                  Tags
                </li>
                <li
                  className={`flex items-center cursor-pointer ${activeView === 'filter-all-tasks' ? 'text-purple-300' : 'text-gray-400 hover:text-white'}`}
                  onClick={() => setActiveView('filter-all-tasks')}
                >
                  <FiList className="mr-2" />
                  All Tasks
                </li>
              </ul>
            )}
          </li>
        </ul>
        {isOpen && (
          <div className="px-4 mb-4">
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-lg font-semibold text-white">Projects</h3>
              <button onClick={() => setIsAddingProject(true)} className="text-purple-400 hover:text-purple-300">
                <FiPlus size={20} />
              </button>
            </div>
            {isAddingProject && (
              <div className="mb-2">
                <input
                  type="text"
                  value={newProjectName}
                  onChange={(e) => setNewProjectName(e.target.value)}
                  className="w-full p-2 rounded bg-gray-700 text-white"
                  placeholder="New project name"
                />
                <div className="flex justify-end mt-2">
                  <button onClick={handleAddProject} className="bg-purple-600 text-white px-3 py-1 rounded mr-2">Add</button>
                  <button onClick={() => setIsAddingProject(false)} className="bg-gray-600 text-white px-3 py-1 rounded">Cancel</button>
                </div>
              </div>
            )}
            <ul className="space-y-4">
              {projects.map(project => (
                <li key={project.id} className="text-gray-300 hover:text-white">
                  <div className="flex items-center justify-between">
                    <div 
                      className="cursor-pointer flex items-center flex-grow"
                      onClick={() => setActiveView(`project-${project.id}`)}
                    >
                      <FiFolder className="mr-2" />
                      <span>{project.name}</span>
                      <span className="ml-auto bg-purple-500 text-white rounded-full px-2 py-1 text-xs">{project.taskCount}</span>
                    </div>
                    <button 
                      onClick={() => setShowOptionsForProject(showOptionsForProject === project.id ? null : project.id)}
                      className="text-gray-400 hover:text-white ml-2"
                    >
                      <FiMoreVertical size={16} />
                    </button>
                  </div>
                  {showOptionsForProject === project.id && (
                    <div className="mt-1 ml-6 space-y-1">
                      <button onClick={() => handleEditProject(project.id, project.name)} className="text-blue-400 hover:text-blue-300 block">
                        <FiEdit2 size={14} className="inline mr-1" /> Edit
                      </button>
                      <button onClick={() => onDeleteProject(project.id)} className="text-red-400 hover:text-red-300 block">
                        <FiTrash2 size={14} className="inline mr-1" /> Delete
                      </button>
                    </div>
                  )}
                  {editingProjectId === project.id && (
                    <div className="mt-1 ml-6">
                      <input
                        type="text"
                        value={editingProjectName}
                        onChange={(e) => setEditingProjectName(e.target.value)}
                        className="w-full p-1 rounded bg-gray-700 text-white mb-1"
                      />
                      <div className="flex space-x-2">
                        <button onClick={() => handleSaveProjectEdit(project.id)} className="text-green-400 hover:text-green-300">
                          <FiSave size={14} className="inline mr-1" /> Save
                        </button>
                        <button onClick={handleCancelEdit} className="text-gray-400 hover:text-gray-300">
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </nav>
      <div className="mt-auto p-4">
        <button onClick={onLogoutClick} className={`flex items-center text-red-400 hover:text-red-300 ${isOpen ? '' : 'justify-center'} w-full`}>
          <FiLogOut className={isOpen ? "mr-2" : ""} />
          {isOpen && "Logout"}
        </button>
      </div>
    </div>
  );
}

export default SidebarContent;