export const filterTodos = (todos, activeView, projects) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return todos.filter(todo => {
    const todoDueDate = todo.dueDate ? new Date(todo.dueDate) : null;
    const createdAt = new Date(todo.createdAt);
    const isMissed = todoDueDate ? todoDueDate < today : (today - createdAt) / (1000 * 60 * 60) > 24;

    switch (activeView) {
      case 'inbox':
        return !todo.completed && !isMissed;
      case 'today':
        return !todo.completed && todoDueDate && todoDueDate.getTime() === today.getTime();
      case 'upcoming':
        return !todo.completed && todoDueDate && todoDueDate.getTime() > today.getTime();
      case 'completed':
        return todo.completed;
      case 'missed':
        return !todo.completed && isMissed;
      default:
        if (activeView.startsWith('project-')) {
          const projectId = activeView.split('-')[1];
          const project = projects.find(p => p.id === projectId);
          return !todo.completed && project && todo.projects && todo.projects.includes(project.name);
        }
        return !todo.completed;
    }
  });
};

// Remove this function if it's not being used elsewhere
// export const updateProjectTaskCounts = (todos, projects) => {
//   const projectCounts = {};
//   todos.forEach(todo => {
//     if (todo.projects) {
//       todo.projects.forEach(project => {
//         projectCounts[project] = (projectCounts[project] || 0) + 1;
//       });
//     }
//   });
//   return projects.map(project => ({
//     ...project,
//     taskCount: projectCounts[project.name] || 0
//   }));
// };

export const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
};