import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FiCheckCircle, FiTag, FiCalendar, FiFolder, FiActivity } from 'react-icons/fi';

function LandingPage() {
  const features = [
    { icon: <FiCheckCircle />, title: 'Task Management', description: 'Create, edit, and organize your tasks effortlessly' },
    { icon: <FiTag />, title: 'Tags', description: 'Categorize tasks with custom tags for easy filtering' },
    { icon: <FiCalendar />, title: 'Due Dates', description: 'Set due dates and never miss a deadline' },
    { icon: <FiFolder />, title: 'Projects', description: 'Group related tasks into projects for better organization' },
    { icon: <FiActivity />, title: 'Activity Logging', description: 'Track your productivity with detailed activity logs' },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-purple-900 to-violet-600 text-white">
      {/* Navigation */}
      <nav className="absolute top-0 right-0 mt-4 mr-4">
        <Link
          to="/login"
          className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-lg text-sm transition duration-300 ease-in-out"
        >
          Log In
        </Link>
      </nav>

      {/* Hero Section */}
      <div className="container mx-auto px-4 py-10 md:py-20 flex flex-col items-center justify-center">
        <motion.div 
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center"
        >
          <motion.h1 
            className="text-4xl sm:text-5xl md:text-7xl font-bold mb-6"
            animate={{ 
              color: ['#ffffff', '#a78bfa', '#ec4899', '#f87171', '#60a5fa', '#ffffff'],
            }}
            transition={{ 
              duration: 10, 
              repeat: Infinity, 
              repeatType: "reverse" 
            }}
          >
            Welcome to Todo App
          </motion.h1>
          <p className="text-lg sm:text-xl md:text-2xl text-purple-200 mb-12 max-w-2xl mx-auto">
            Organize your tasks, boost your productivity, and take control of your day with our powerful and intuitive todo application.
          </p>
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Link
              to="/signup"
              className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-8 rounded-lg text-lg transition duration-300 ease-in-out transform hover:-translate-y-1 inline-block"
            >
              Get Started
            </Link>
          </motion.div>
        </motion.div>
      </div>

      {/* Features Section */}
      <div className="bg-gray-800 bg-opacity-50 py-10 md:py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-center mb-8 md:mb-12">Key Features</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                whileHover={{ scale: 1.03, transition: { duration: 0.2 } }}
                className="bg-gray-700 bg-opacity-50 p-6 rounded-lg"
              >
                <div className="text-purple-400 text-3xl mb-4">
                  {feature.icon}
                </div>
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-300">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="container mx-auto px-4 py-20 text-center">
        <h2 className="text-3xl sm:text-4xl font-bold mb-8">Ready to get organized?</h2>
        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <Link
            to="/signup"
            className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-8 rounded-lg text-lg transition duration-300 ease-in-out inline-block"
          >
            Sign Up Now
          </Link>
        </motion.div>
        <p className="mt-4 text-purple-200">
          Already have an account? <Link to="/login" className="text-purple-400 hover:text-purple-300 underline">Log in</Link>
        </p>
      </div>

      {/* Footer */}
      <footer className="bg-gray-900 text-gray-300 py-8">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-4 md:mb-0">
              <h3 className="text-2xl font-bold text-purple-400">Todo App</h3>
              <p className="mt-2">Simplify your life, one task at a time.</p>
            </div>
            <div className="flex space-x-4">
              <a href="#" className="hover:text-purple-400">About</a>
              <a href="#" className="hover:text-purple-400">Privacy Policy</a>
              <a href="#" className="hover:text-purple-400">Terms of Service</a>
              <a href="#" className="hover:text-purple-400">Contact</a>
            </div>
          </div>
          <div className="mt-8 text-center text-sm">
            © {new Date().getFullYear()} Todo App. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  );
}

export default LandingPage;