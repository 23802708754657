import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { FiArrowLeft, FiUser, FiInfo } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

function ActivityLog() {
  const [activities, setActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();
  const history = useHistory();

  useEffect(() => {
    const fetchActivities = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const q = query(
          collection(db, 'activityLogs'),
          where('userId', '==', currentUser.uid),
          orderBy('timestamp', 'desc')
        );
        const querySnapshot = await getDocs(q);
        const activityList = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate()
        }));
        setActivities(activityList);
      } catch (error) {
        console.error('Error fetching activities:', error);
        setError('Failed to load activities. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchActivities();
  }, [currentUser]);

  const getEmoji = (action) => {
    switch (action) {
      case 'Added Todo': return '✅';
      case 'Deleted Todo': return '🗑️';
      case 'Updated Profile': return '👤';
      case 'Changed Password': return '🔒';
      case 'Updated Email': return '📧';
      case 'Updated Profile Photo': return '📸';
      case 'Marked Todo as Complete': return '✔️';
      case 'Marked Todo as Incomplete': return '↩️';
      default: return '📝';
    }
  };

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
    </div>;
  }

  if (error) {
    return <div className="text-red-500 text-center mt-8">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8 bg-gray-100 min-h-screen">
      <div className="flex items-center mb-6">
        <button 
          onClick={() => history.goBack()} 
          className="mr-4 text-purple-600 hover:text-purple-800"
        >
          <FiArrowLeft size={24} />
        </button>
        <h2 className="text-3xl font-bold text-gray-800">Activity Log</h2>
      </div>
      {activities.length === 0 ? (
        <p className="text-center text-gray-600">No activities found.</p>
      ) : (
        <ul className="space-y-4">
          {activities.map((activity, index) => (
            <li key={index} className="bg-white shadow-md rounded-lg p-4">
              <div className="flex items-start">
                <div className="mr-4 text-2xl">{getEmoji(activity.action)}</div>
                <div className="flex-grow">
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center">
                      {currentUser.photoURL ? (
                        <img src={currentUser.photoURL} alt="Profile" className="w-6 h-6 rounded-full mr-2" />
                      ) : (
                        <FiUser className="w-6 h-6 rounded-full mr-2 text-gray-500" />
                      )}
                      <p className="font-semibold text-gray-800">{currentUser.displayName || currentUser.email}</p>
                    </div>
                    <p className="text-sm text-gray-500">
                      {activity.timestamp ? formatDate(activity.timestamp) : 'Date not available'}
                    </p>
                  </div>
                  <p className="font-medium text-gray-700 mb-2">{activity.action}</p>
                  {activity.details && Object.entries(activity.details).map(([key, value]) => {
                    // Skip displaying any key that includes 'id' or 'ID'
                    if (key.toLowerCase().includes('id')) return null;
                    return (
                      <div key={key} className="text-sm text-gray-600 flex items-center">
                        <span className="font-medium mr-1">{key}:</span> {value}
                        <FiInfo 
                          className="ml-2 text-gray-400 cursor-help" 
                          data-tooltip-id={`${index}-${key}`}
                          data-tooltip-content={`This is the ${key} for the ${activity.action} action.`}
                        />
                        <Tooltip id={`${index}-${key}`} />
                      </div>
                    );
                  })}
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default ActivityLog;