import React, { useState, useEffect } from 'react';
import { FiTag, FiCalendar, FiFolder, FiCheck, FiAlertTriangle } from 'react-icons/fi';
import { motion, AnimatePresence } from 'framer-motion';

function FilterByTags({ todos, tags, onToggle, onDelete, onEdit }) {
  const [selectedTags, setSelectedTags] = useState([]);
  const [filteredTodos, setFilteredTodos] = useState([]);

  useEffect(() => {
    if (selectedTags.length > 0) {
      const filtered = todos.filter(todo => 
        selectedTags.every(tag => todo.tags && todo.tags.includes(tag))
      );
      setFilteredTodos(filtered);
    } else {
      setFilteredTodos(todos);
    }
  }, [selectedTags, todos]);

  const handleTagToggle = (tag) => {
    setSelectedTags(prev => 
      prev.includes(tag) ? prev.filter(t => t !== tag) : [...prev, tag]
    );
  };

  const formatDate = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  };

  const getTaskStatus = (todo) => {
    if (todo.completed) return 'done';
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    if (todo.dueDate) {
      const dueDate = new Date(todo.dueDate);
      dueDate.setHours(0, 0, 0, 0);
      if (dueDate.getTime() === now.getTime()) return 'pending';
      if (dueDate < now) return 'missed';
    } else {
      const createdAt = new Date(todo.createdAt);
      if ((now - createdAt) / (1000 * 60 * 60) > 24) return 'missed';
    }
    return 'pending';
  };

  const renderTaskIcon = (todo, status) => {
    if (status === 'missed' && !todo.completed) {
      return <FiAlertTriangle className="inline-block mr-2 text-yellow-500" />;
    } else if (todo.emoji) {
      return <span className="mr-2">{todo.emoji}</span>;
    }
    return null;
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 24
      }
    },
    hover: {
      scale: 1.0125,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 10
      }
    }
  };

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold text-white mb-4">Tags</h2>
      <div className="flex flex-wrap gap-2 mb-4">
        {tags.map(tag => (
          <button
            key={tag}
            onClick={() => handleTagToggle(tag)}
            className={`px-3 py-1 rounded-full text-sm ${
              selectedTags.includes(tag) ? 'bg-purple-600 text-white' : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
            }`}
          >
            <FiTag className="inline-block mr-1" />
            {tag}
          </button>
        ))}
      </div>
      <motion.ul className="space-y-2">
        <AnimatePresence>
          {filteredTodos.map((todo) => {
            const status = getTaskStatus(todo);
            return (
              <motion.li
                key={todo.id}
                variants={itemVariants}
                initial="hidden"
                animate="visible"
                exit="hidden"
                whileHover="hover"
                layout
                className={`flex flex-col sm:flex-row items-start sm:items-center justify-between p-3 rounded-lg cursor-pointer ${
                  status === 'done' ? 'bg-green-900 bg-opacity-50' :
                  status === 'missed' ? 'bg-red-900 bg-opacity-50' :
                  'bg-gray-800 bg-opacity-50'
                }`}
                onClick={() => onEdit(todo)}
              >
                <div className="flex-grow mb-2 sm:mb-0">
                  <span className={`text-gray-200 ${todo.completed ? 'line-through' : ''}`}>
                    {renderTaskIcon(todo, status)}
                    {todo.content}
                  </span>
                  <div className="flex flex-wrap items-center mt-1 space-x-2 text-xs">
                    {todo.dueDate && (
                      <span className="flex items-center text-gray-400">
                        <FiCalendar className="mr-1" />
                        {formatDate(todo.dueDate)}
                      </span>
                    )}
                    {todo.projects && todo.projects.map(project => (
                      <span
                        key={project}
                        className="flex items-center px-2 py-1 rounded-full text-white bg-blue-600"
                      >
                        <FiFolder className="mr-1" />
                        {project}
                      </span>
                    ))}
                    {todo.tags && todo.tags.map(tag => (
                      <span
                        key={tag}
                        className="flex items-center px-2 py-1 rounded-full text-white bg-purple-600"
                      >
                        <FiTag className="mr-1" />
                        {tag}
                      </span>
                    ))}
                  </div>
                </div>
                {(status === 'pending' || status === 'missed') && (
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onToggle(todo.id);
                    }}
                    className="mt-2 sm:mt-0 ml-0 sm:ml-4 px-3 py-1 bg-purple-600 text-white rounded-md hover:bg-purple-700 focus:outline-none"
                  >
                    Done
                  </motion.button>
                )}
                {status === 'done' && (
                  <FiCheck className="mt-2 sm:mt-0 ml-0 sm:ml-4 text-green-300" size={20} />
                )}
              </motion.li>
            );
          })}
        </AnimatePresence>
      </motion.ul>
    </div>
  );
}

export default FilterByTags;