import React from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import SidebarContent from './SidebarContent';

function DesktopSidebar({ isOpen, setIsOpen, ...props }) {
  return (
    <div className={`hidden md:flex flex-col transition-all duration-300 ease-in-out bg-black bg-opacity-30 backdrop-filter backdrop-blur-lg ${isOpen ? 'w-64' : 'w-16'}`}>
      <div className="flex items-center justify-between p-4">
        {isOpen && <h2 className="text-xl font-bold text-white">Menu</h2>}
        <button 
          onClick={() => setIsOpen(!isOpen)} 
          className={`text-white p-2 rounded-full hover:bg-purple-600 ${isOpen ? '' : 'mx-auto'}`}
        >
          {isOpen ? <FiChevronLeft size={24} /> : <FiChevronRight size={24} />}
        </button>
      </div>
      <SidebarContent isOpen={isOpen} {...props} />
    </div>
  );
}

export default DesktopSidebar;