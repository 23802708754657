import React, { useState, useEffect } from 'react';
import { FiCalendar, FiTag, FiFolder, FiX, FiCheck, FiClock, FiAlertTriangle } from 'react-icons/fi';
import { logActivity } from '../utils/logActivity';
import { useAuth } from '../contexts/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { stringToColor } from '../utils/todoUtils';

function TaskList({ todos, onToggle, onDelete, onEdit, projects, tags, onAddTag }) {
  const { currentUser } = useAuth();
  const [selectedTask, setSelectedTask] = useState(null);
  const [editedTask, setEditedTask] = useState(null);

  useEffect(() => {
    if (selectedTask) {
      setEditedTask({
        ...selectedTask,
        dueDate: selectedTask.dueDate ? new Date(selectedTask.dueDate) : null,
        projects: selectedTask.projects ? selectedTask.projects.map(p => ({ value: p, label: p })) : [],
        tags: selectedTask.tags ? selectedTask.tags.map(t => ({ value: t, label: t })) : []
      });
    }
  }, [selectedTask]);

  const formatDate = (date) => {
    if (!date) return '';
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  };

  const getTaskStatus = (todo) => {
    if (todo.completed) return 'done';
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    if (todo.dueDate) {
      const dueDate = new Date(todo.dueDate);
      dueDate.setHours(0, 0, 0, 0);
      if (dueDate.getTime() === now.getTime()) return 'pending'; // Due today
      if (dueDate < now) return 'missed';
    } else {
      const createdAt = new Date(todo.createdAt);
      if ((now - createdAt) / (1000 * 60 * 60) > 24) return 'missed';
    }
    return 'pending';
  };

  const handleDone = async (todoId) => {
    await onToggle(todoId);
    const todo = todos.find(t => t.id === todoId);
    await logActivity(currentUser.uid, `Marked Todo as ${todo.completed ? 'Complete' : 'Incomplete'}`, { todoId, content: todo.content });
  };

  const handleTaskClick = (todo) => {
    setSelectedTask(todo);
  };

  const handleCloseModal = () => {
    setSelectedTask(null);
    setEditedTask(null);
  };

  const handleEditField = (field, value) => {
    setEditedTask({ ...editedTask, [field]: value });
  };

  const handleSaveChanges = async () => {
    const updatedTask = {
      ...editedTask,
      projects: editedTask.projects.map(p => p.value),
      tags: editedTask.tags.map(t => t.value)
    };
    await onEdit(updatedTask);
    handleCloseModal();
  };

  const handleDeleteTask = async () => {
    await onDelete(selectedTask.id);
    handleCloseModal();
  };

  const handleAddNewTag = (inputValue) => {
    const newTag = { value: inputValue, label: inputValue };
    handleEditField('tags', [...editedTask.tags, newTag]);
    onAddTag(inputValue);
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 24
      }
    },
    hover: {
      scale: 1.0125,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 10
      }
    }
  };

  const getTagColor = (tag) => {
    const baseColors = [
      'rgba(99, 102, 241, 0.7)',  // Indigo
      'rgba(167, 139, 250, 0.7)', // Purple
      'rgba(236, 72, 153, 0.7)',  // Pink
      'rgba(248, 113, 113, 0.7)', // Red
      'rgba(251, 146, 60, 0.7)',  // Orange
      'rgba(250, 204, 21, 0.7)',  // Yellow
      'rgba(34, 197, 94, 0.7)',   // Green
      'rgba(45, 212, 191, 0.7)',  // Teal
      'rgba(14, 165, 233, 0.7)',  // Sky
    ];
    const index = tag.length % baseColors.length;
    return baseColors[index];
  };

  const getProjectColor = (project) => {
    const baseColors = [
      'rgba(79, 70, 229, 0.7)',   // Indigo
      'rgba(147, 51, 234, 0.7)',  // Purple
      'rgba(219, 39, 119, 0.7)',  // Pink
      'rgba(220, 38, 38, 0.7)',   // Red
      'rgba(234, 88, 12, 0.7)',   // Orange
      'rgba(202, 138, 4, 0.7)',   // Yellow
      'rgba(22, 163, 74, 0.7)',   // Green
      'rgba(20, 184, 166, 0.7)',  // Teal
      'rgba(3, 105, 161, 0.7)',   // Sky
    ];
    const index = project.length % baseColors.length;
    return baseColors[index];
  };

  const renderTaskIcon = (todo, status) => {
    if (status === 'missed' && !todo.completed) {
      return <FiAlertTriangle className="inline-block mr-2 text-yellow-500" />;
    } else if (todo.emoji) {
      return <span className="mr-2">{todo.emoji}</span>;
    }
    return null;
  };

  return (
    <>
      <motion.ul className="space-y-2">
        <AnimatePresence>
          {todos.map((todo) => {
            const status = getTaskStatus(todo);
            return (
              <motion.li
                key={todo.id}
                variants={itemVariants}
                initial="hidden"
                animate="visible"
                exit="hidden"
                whileHover="hover"
                layout
                className={`flex flex-col sm:flex-row items-start sm:items-center justify-between p-3 rounded-lg cursor-pointer ${
                  status === 'done' ? 'bg-green-900 bg-opacity-50' :
                  status === 'missed' ? 'bg-red-900 bg-opacity-50' :
                  'bg-gray-800 bg-opacity-50'
                }`}
                onClick={() => handleTaskClick(todo)}
              >
                <div className="flex-grow mb-2 sm:mb-0">
                  <span className={`text-gray-200 ${todo.completed ? 'line-through' : ''}`}>
                    {renderTaskIcon(todo, status)}
                    {todo.content}
                  </span>
                  <div className="flex flex-wrap items-center mt-1 space-x-2 text-xs">
                    {todo.dueDate && (
                      <span className="flex items-center text-gray-400">
                        <FiCalendar className="mr-1" />
                        {formatDate(todo.dueDate)}
                      </span>
                    )}
                    {todo.projects && todo.projects.map(project => (
                      <span
                        key={project}
                        className="flex items-center px-2 py-1 rounded-full text-white"
                        style={{ backgroundColor: getProjectColor(project) }}
                      >
                        <FiFolder className="mr-1" />
                        {project}
                      </span>
                    ))}
                    {todo.tags && todo.tags.map(tag => (
                      <span
                        key={tag}
                        className="flex items-center px-2 py-1 rounded-full text-white"
                        style={{ backgroundColor: getTagColor(tag) }}
                      >
                        <FiTag className="mr-1" />
                        {tag}
                      </span>
                    ))}
                  </div>
                </div>
                {(status === 'pending' || status === 'missed') && (
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDone(todo.id);
                    }}
                    className="mt-2 sm:mt-0 ml-0 sm:ml-4 px-3 py-1 bg-purple-600 text-white rounded-md hover:bg-purple-700 focus:outline-none"
                  >
                    Done
                  </motion.button>
                )}
                {status === 'done' && (
                  <FiCheck className="mt-2 sm:mt-0 ml-0 sm:ml-4 text-green-300" size={20} />
                )}
              </motion.li>
            );
          })}
        </AnimatePresence>
      </motion.ul>

      {selectedTask && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-gray-800 rounded-lg p-6 w-full max-w-md text-white">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Edit Task</h2>
              <button onClick={handleCloseModal} className="text-gray-400 hover:text-white">
                <FiX size={24} />
              </button>
            </div>
            <input
              type="text"
              value={editedTask?.content || ''}
              onChange={(e) => handleEditField('content', e.target.value)}
              className="w-full p-2 bg-gray-700 border border-gray-600 rounded mb-4 text-white"
            />
            <div className="space-y-4">
              <div className="flex items-center">
                <FiCalendar className="mr-2 text-purple-400" />
                <DatePicker
                  selected={editedTask?.dueDate}
                  onChange={(date) => handleEditField('dueDate', date)}
                  className="p-2 bg-gray-700 border border-gray-600 rounded w-full text-white"
                  placeholderText="Select due date"
                />
              </div>
              <div className="flex items-center">
                <FiTag className="mr-2 text-purple-400" />
                <Select
                  isMulti
                  options={tags.map(tag => ({ value: tag, label: tag }))}
                  value={editedTask?.tags}
                  onChange={(selectedOptions) => handleEditField('tags', selectedOptions)}
                  onCreateOption={handleAddNewTag}
                  className="w-full"
                  classNamePrefix="react-select"
                  placeholder="Select or create tags"
                  styles={{
                    control: (base) => ({
                      ...base,
                      backgroundColor: '#374151',
                      borderColor: '#4B5563',
                    }),
                    menu: (base) => ({
                      ...base,
                      backgroundColor: '#374151',
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isFocused ? '#4B5563' : '#374151',
                      color: 'white',
                    }),
                    multiValue: (base) => ({
                      ...base,
                      backgroundColor: '#4B5563',
                    }),
                    multiValueLabel: (base) => ({
                      ...base,
                      color: 'white',
                    }),
                    multiValueRemove: (base) => ({
                      ...base,
                      color: 'white',
                      ':hover': {
                        backgroundColor: '#6B7280',
                        color: 'white',
                      },
                    }),
                  }}
                />
              </div>
              <div className="flex items-center">
                <FiFolder className="mr-2 text-purple-400" />
                <Select
                  isMulti
                  options={projects.map(project => ({ value: project.name, label: project.name }))}
                  value={editedTask?.projects}
                  onChange={(selectedOptions) => handleEditField('projects', selectedOptions)}
                  className="w-full"
                  classNamePrefix="react-select"
                  placeholder="Select projects"
                  styles={{
                    control: (base) => ({
                      ...base,
                      backgroundColor: '#374151',
                      borderColor: '#4B5563',
                    }),
                    menu: (base) => ({
                      ...base,
                      backgroundColor: '#374151',
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isFocused ? '#4B5563' : '#374151',
                      color: 'white',
                    }),
                    multiValue: (base) => ({
                      ...base,
                      backgroundColor: '#4B5563',
                    }),
                    multiValueLabel: (base) => ({
                      ...base,
                      color: 'white',
                    }),
                    multiValueRemove: (base) => ({
                      ...base,
                      color: 'white',
                      ':hover': {
                        backgroundColor: '#6B7280',
                        color: 'white',
                      },
                    }),
                  }}
                />
              </div>
            </div>
            <div className="flex justify-between mt-6">
              <button
                onClick={handleDeleteTask}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition duration-300"
              >
                Delete
              </button>
              <button
                onClick={handleSaveChanges}
                className="px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-700 transition duration-300"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TaskList;