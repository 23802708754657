import React, { useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { FiCalendar, FiTag, FiFolder, FiSmile } from 'react-icons/fi';
import EmojiPicker from 'emoji-picker-react';
import "react-datepicker/dist/react-datepicker.css";

function AddTodoForm({ onAddTodo, existingTags, projects, onAddTag }) {
  const [newTodo, setNewTodo] = useState('');
  const [dueDate, setDueDate] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showTagsDropdown, setShowTagsDropdown] = useState(false);
  const [showProjectsDropdown, setShowProjectsDropdown] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState(null);

  const formRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setShowDatePicker(false);
        setShowTagsDropdown(false);
        setShowProjectsDropdown(false);
        setShowEmojiPicker(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newTodo.trim() === '') return;
    onAddTodo(
      newTodo,
      dueDate ? dueDate.toISOString() : null,
      selectedTags.map(t => t.value),
      selectedProjects.map(p => p.value),
      selectedEmoji ? selectedEmoji.emoji : null  // Pass the emoji separately
    );
    setNewTodo('');
    setDueDate(null);
    setSelectedTags([]);
    setSelectedProjects([]);
    setSelectedEmoji(null);
  };

  const handleCreateTag = (inputValue) => {
    const newTag = { value: inputValue, label: inputValue };
    onAddTag(inputValue);
    setSelectedTags([...selectedTags, newTag]);
    return newTag;
  };

  const toggleDropdown = (dropdown) => {
    setShowDatePicker(dropdown === 'date' ? !showDatePicker : false);
    setShowTagsDropdown(dropdown === 'tags' ? !showTagsDropdown : false);
    setShowProjectsDropdown(dropdown === 'projects' ? !showProjectsDropdown : false);
    setShowEmojiPicker(dropdown === 'emoji' ? !showEmojiPicker : false);
  };

  const onEmojiClick = (emojiObject) => {
    setSelectedEmoji(emojiObject);
    setShowEmojiPicker(false);
  };

  return (
    <form onSubmit={handleSubmit} className="mb-4 relative" ref={formRef}>
      <div className="flex flex-col space-y-2">
        <div className="flex items-center">
          {selectedEmoji && (
            <span className="mr-2 text-xl">{selectedEmoji.emoji}</span>
          )}
          <input
            type="text"
            value={newTodo}
            onChange={(e) => setNewTodo(e.target.value)}
            placeholder="Add a new Task..."
            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600"
          />
        </div>
        <div className="flex justify-between items-center">
          <div className="flex space-x-2">
            <button
              type="button"
              onClick={() => toggleDropdown('date')}
              className="p-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600"
            >
              <FiCalendar />
            </button>
            <button
              type="button"
              onClick={() => toggleDropdown('tags')}
              className="p-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600"
            >
              <FiTag />
            </button>
            <button
              type="button"
              onClick={() => toggleDropdown('projects')}
              className="p-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600"
            >
              <FiFolder />
            </button>
            <button
              type="button"
              onClick={() => toggleDropdown('emoji')}
              className="p-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600"
            >
              <FiSmile />
            </button>
          </div>
          <button type="submit" className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700">
            Add
          </button>
        </div>
      </div>
      {showDatePicker && (
        <div className="absolute left-0 mt-2 z-10 bg-white rounded-lg shadow-lg">
          <DatePicker
            selected={dueDate}
            onChange={(date) => {
              setDueDate(date);
              setShowDatePicker(false);
            }}
            inline
          />
        </div>
      )}
      {showTagsDropdown && (
        <div className="absolute left-0 mt-2 w-64 z-10">
          <CreatableSelect
            isMulti
            options={existingTags.map(tag => ({ value: tag, label: tag }))}
            value={selectedTags}
            onChange={setSelectedTags}
            onCreateOption={handleCreateTag}
            placeholder="Select or create tags"
            className="react-select-container"
            classNamePrefix="react-select"
            formatCreateLabel={(inputValue) => `Create tag "${inputValue}"`}
          />
        </div>
      )}
      {showProjectsDropdown && (
        <div className="absolute left-0 mt-2 w-64 z-10">
          <Select
            isMulti
            options={projects.map(project => ({ value: project.name, label: project.name }))}
            value={selectedProjects}
            onChange={setSelectedProjects}
            placeholder="Select projects"
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>
      )}
      {showEmojiPicker && (
        <div className="absolute left-0 mt-2 z-10">
          <EmojiPicker onEmojiClick={onEmojiClick} />
        </div>
      )}
    </form>
  );
}

export default AddTodoForm;